<template>
	<div class="profile-page">
		<div class="container">
			<template v-if="isMobile">
				<div class="profile-page__container">
					<tk-link
						v-if="isShowNotProfile"
						to="/profile"
						class="profile-page__back-btn"
						kind="back-button"
					>
						<template #icon-before>
							<tk-svg type="arrow-top" />
						</template>
						{{ $t('links.back') }}
					</tk-link>

					<component :is="current" />
				</div>
			</template>

			<template v-else>
				<Sidebar class="profile-page__sidebar" />

				<div class="profile-page__container">
					<tk-link
						v-if="$route.path.split('/').length > 3 && !$route.name.includes('profile.trips')"
						:to="pathBack"
						class="profile-page__back-btn profile-back-btn"
						kind="back-button"
					>
						<template #icon-before>
							<tk-svg type="arrow-top" />
						</template>
						{{ $t('links.back') }}
					</tk-link>

					<router-view />
				</div>
			</template>
		</div>
	</div>
</template>

<script>
import Sidebar from '@/components/Sidebar'

export default {
	name: 'Profile',
	components: { Sidebar },
	data() {
		return {
			isMobile: false
		}
	},
	created() {
		this.onResize()
		window.addEventListener('resize', this.onResize, { passive: true })
	},
	computed: {
		current() {
			return this.$route.name === 'Profile' ? 'Sidebar' : 'router-view'
		},
		isShowNotProfile() {
			return this.$route.name !== 'Sidebar'
		},
		pathBack() {
      const target = this.$route.path.split('/').length
			return this.$route.path.split('/').slice(0, target - 1).join('/')
		}
	},
	methods: {
		onResize() {
			this.isMobile = window.innerWidth < 800

			if (!this.isMobile && !this.isShowNotProfile) {
				this.$router.push('/profile/anketa')
			}
		}
	}
}
</script>

<style lang="sass">
.profile-page
  position: relative
  padding-top: 60px
  padding-bottom: 60px
  background-color: white

  .container
    display: flex
    width: 1300px

  &__sidebar
    width: 100%
    max-width: 210px
    min-width: 210px
    margin-right: 80px

  &__container
    position: relative

    width: 100%
    min-height: 800px

  &__back-btn
    position: absolute
    top: -40px

// 1080
// 996
@media screen and (max-width: 1180px)
  .profile-page
    &__sidebar
      margin-right: 60px

// 900
@media screen and (max-width: 1024px)
  .profile-page
    &__sidebar
      margin-right: 40px

// 768
@media screen and (max-width: 912px)
  .profile-page
    &__sidebar
      margin-right: 20px

@media screen and (max-width: 912px)
  .profile-page


    //.container
    //  justify-content: center
</style>
