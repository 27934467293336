<template>
	<div class="sidebar">
		<div class="sidebar__title sidebar-title">
			<h3 class="sidebar-title__text">{{ $t('titles.profile') }}</h3>
			<router-link class="sidebar-title__link" to="/profile/anketa">
				<tk-svg type="edit" />
			</router-link>
		</div>
		<div class="sidebar__info info">
			<div class="info__userpic">
				<tk-image
					:src="$store.getters.avatar"
					width="60"
					height="60"
					:alt="$t('images.alts.user.photo')"
				/>
			</div>
			<div class="info__username">{{ $store.getters.fullName }}</div>
			<Progress />
		</div>

		<ul class="sidebar__menu sidebar-menu">
			<template v-for="link in menu" :key="link.title">
				<li v-if="link.isShow" class="sidebar-menu__item sidebar-item">
					<router-link
						:to="link.link"
						class="sidebar-item__link"
						active-class="active"
					>
						<tk-svg class="icon-before" :type="link.icon" />
						{{ $t(`links.profile.sidebar.${link.title}`) }}
<!--            <span v-if="link.title == 'booking' && newBookingsCount">{{ newBookingsCount }}</span>-->
						<tk-svg class="icon-after" type="arrow-top" />
					</router-link>
				</li>
			</template>
		</ul>
	</div>
</template>

<script>
import Progress from '@/components/Progress'

export default {
	name: 'Sidebar',
	components: { Progress },
	computed: {
    newBookingsCount() {
      return this.$store.state.helpers.newBookingsCount
    },
		isMaster() {
			return this.$store.getters.isMaster
		},
		isGuest() {
			return this.$store.getters.isGuest
		},
		menu() {
			return [
				{
					title: 'anketa',
					icon: 'account',
					link: '/profile/anketa',
					isShow: true
				},
				{
					title: 'progress',
					icon: 'sidebar-progress',
					link: '/profile/progress',
					isShow: false
				},
				{
					title: 'my-housing',
					icon: 'briefcase',
					link: '/profile/my-housing',
					isShow: this.isMaster
				},
				{
					title: 'chat',
					icon: 'sidebar-message',
					link: '/profile/chat',
					isShow: true
				},
				{
					title: 'booking',
					icon: 'users',
					link: '/profile/booking',
					isShow: this.isMaster
				},
				{
					title: 'trips',
					icon: 'sidebar-case',
					link: '/profile/trips',
					isShow: this.isGuest
				},
				{
					title: 'reviews',
					icon: 'sidebar-review',
					link: '/profile/reviews',
					isShow: true
				},
				{
					title: 'settings',
					icon: 'sidebar-settings',
					link: '/profile/settings',
					isShow: true
				},
				{
					title: 'faq',
					icon: 'sidebar-question',
					link: '/profile/faq',
					isShow: true
				},
				{
					title: 'feedback',
					icon: 'sidebar-feedback',
					link: '/profile/feedback',
					isShow: true
				}
			]
		}
	}
}
</script>
