<template>
	<div class="progress-status">
		<img
			class="progress-status__image"
			:src="content.icon"
			height="20"
			width="20"
			alt="Медаль"
		/>
		<p class="progress-status__text">{{ content.title }}</p>
	</div>
</template>

<script>
import progress_icon from '@/constants/progress'
import { GET, CLIENT, OWNER } from '@/constants/store/progress/actions'

const _config = {
  main: {
    action: GET,
    content: 'content'
  },
  client: {
    action: CLIENT,
    content: 'client'
  },
  owner: {
    action: OWNER,
    content: 'owner'
  }
}

export default {
  props: {
    type: {
      type: String,
      default: 'main' // owner/client/main
    }
  },
	async created() {
    if (this.content?.title) {
      return
    }

		await this.$store.dispatch(this.config.action)
	},
  computed: {
    config() {
      return _config[this.type]
    },
    values() {
      return this.$store.state.progress[this.config.content]
    },
    content() {
      const result = this.$store.state.progress[this.config.content]
      const status = result?.statuses?.find(itm => itm.id === result.user_data.status_id)
      return { ...status, icon: progress_icon[status?.id] }
    }
  }
}
</script>

<style lang="sass">
.progress-status
  display: flex
  align-items: center
  justify-content: center

  &__image
    width: 20px
    height: 20px

  &__text
    margin: 0
    font-weight: 400
    font-size: 12px
    line-height: 14px
    color: $black-secondary
</style>